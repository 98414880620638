import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

import {
  AppBar,
  CssBaseline,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { BiCodeBlock } from 'react-icons/bi';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AssignmentIcon from '@material-ui/icons/Assignment';
import frontEndConfigFile from '../../configFiles/frontendConfigFile';
import { codeFrameCodeShareUrlForModerators, codeFrameURL } from '../../configFiles/config';
import './moderatorTopNavBar.css';
import myAnatomyLogo from '../../images/myAnatomyLogo.png';
import { defaultParticipantName } from '../../configFiles/config';
import Loader from '../Loader';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import { theme } from '../../utils/uiTheme';

class ModeratorTopNavBar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showModeratorOptionsDialog: false,
      showParticipantsBoardDialogModal: false,
      openedCodeShareWindows: [],
      openedNotesWindow: [],
      openedWhiteboardWindow: [],
    };
  }

  
  componentDidMount () {
    window.addEventListener('beforeunload', this.closeEventForCodeShareBoard);
    window.addEventListener('beforeunload', this.closeEventForNotesBoard);
    window.addEventListener('beforeunload', this.closeEventForWhiteboardBoard);
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.closeEventForCodeShareBoard);
    window.removeEventListener('beforeunload', this.closeEventForNotesBoard);
    window.removeEventListener('beforeunload', this.closeEventForWhiteboardBoard);
  }

  closeEventForWhiteboardBoard = () => {
    const { openedWhiteboardWindow } = this.state;

    openedWhiteboardWindow.forEach((board) => {
      board.close();
    }
    );
    this.setState({
      openedWhiteboardWindow: [],
    });
  }

  closeEventForNotesBoard = () => {
    const { openedNotesWindow } = this.state;

    openedNotesWindow.forEach((board) => {
      board.close();
    }
    );

    this.setState({
      openedNotesWindow: [],
    });
  }


  closeEventForCodeShareBoard = () => {
    const {
      openedCodeShareWindows,
    } = this.state;

    openedCodeShareWindows.forEach(board => {
      board.close();
    });

    this.setState({
      openedCodeShareWindows: [],
    });
  }

  showCodeShareBoard = (participantName, participantToken) => {
    const {
      token,
      meetingId,
      externalMeetingId,
    } = this.props;
    const {
      openedCodeShareWindows,
    } = this.state;
    let windowHeight = 440;
    let windowWidth = 630;

    let adjustFromTop = 0;

    if (window.screen) {
      windowHeight = (window.screen.availHeight * 70) / 100;
      windowWidth = (window.screen.availWidth);
      adjustFromTop = (window.screen.availHeight * 15) / 100;
    }

    let codeShareURL = codeFrameCodeShareUrlForModerators;

    codeShareURL = codeShareURL.replace('<<meetingId>>', meetingId);
    codeShareURL = codeShareURL.replace('<<meetingToken>>', token);
    codeShareURL = codeShareURL.replace('<<participantToken>>', participantToken);
    codeShareURL = codeShareURL.replace('<<externalMeetingId>>', externalMeetingId);
    codeShareURL = codeShareURL.replace('<<participantName>>', participantName);
    const openWindow = window.open(
      codeShareURL,
      '_blank',
        `
          width=${windowWidth},
          height=${windowHeight},
          top=${adjustFromTop},
          location=no,
        `
    );
    openedCodeShareWindows.push(openWindow);
    this.setState({
      openedCodeShareWindows,
    });
  }

  openParticipantsBoardDetailsDialog = (meetingId) => {
    const {
      fetchParticipantsSharingBoard,
    } = this.props;
    this.setState(() => ({
      showParticipantsBoardDialogModal: true,
    }));
    fetchParticipantsSharingBoard(meetingId);
  }

  closeParticipantsBoardDetailsDialog = () => {
    this.setState(() => ({
      showParticipantsBoardDialogModal: false,
    }));
  }

  openModeratorOptionsDialog = () => {
    this.setState(() => ({
      showModeratorOptionsDialog: true,
    }));
  }

  closeModeratorOptionsDialog = () => {
    this.setState(() => ({
      showModeratorOptionsDialog: false,
    }));
  }

  getMeetingSubmissions = () => {
    const {
      getSubmissionsForMeeting,
    } = this.props;

    getSubmissionsForMeeting();
  }

  downloadMeetingContentHistory = () => {
    const {
      meetingContentHistory,
    } = this.props;

    let finalTextToDownload = '';

    meetingContentHistory && meetingContentHistory.forEach((contentRecord) => {
      const clientDetails = contentRecord.clientDetails;
      const name = clientDetails.name || defaultParticipantName;

      finalTextToDownload += `Participant Name: ${name}\n\n`;

      const usersContentHistory = contentRecord.contentHistory;
      usersContentHistory && usersContentHistory.forEach((usersContentHistoryRecord) => {
        const createdAt = moment(usersContentHistoryRecord.createdAt).format('DD-MM-YYYY HH:mm:ss');
        const submission = usersContentHistoryRecord.content;
        const {
          code,
          language,
        } = submission;

        finalTextToDownload += `${language}\t\t\t\t${createdAt}\n\n`;
        finalTextToDownload += code;
        finalTextToDownload += '\n\n\n\n';
      });
      finalTextToDownload += '\n\n--------------------------------------------------------------\n\n';
    });

    // const header = '<html xmlns:o=\'urn:schemas-microsoft-com:office:office\' ' +
    // 'xmlns:w=\'urn:schemas-microsoft-com:office:word\' ' +
    // 'xmlns=\'http://www.w3.org/TR/REC-html40\'>' +
    // '<head><meta charset=\'utf-8\'><title>Editor Content</title></head><body>';
    // const footer = '</body></html>';


    var sourceHTML = finalTextToDownload;

    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement('a');
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = `submissions.doc`;
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }

  showNotesView = () => {
    let windowHeight = 440;
    let windowWidth = 630;

    let adjustFromTop = 0;

    const { openedNotesWindow } = this.state;

    if (window.screen) {
      windowHeight = (window.screen.availHeight * 70) / 100;
      windowWidth = (window.screen.availWidth);
      adjustFromTop = (window.screen.availHeight * 15) / 100;
    }

    let codeframeUrl = frontEndConfigFile.CODE_FRAME_URL.frontend;
    codeframeUrl = codeframeUrl.replace(/<<codeFrameConfigUrl>>/g, codeFrameURL);

    let notesViewUrl = frontEndConfigFile.NOTES.url;
    notesViewUrl = notesViewUrl.replace('<<codeFrameUrl>>', codeframeUrl);
    const openWindow = window.open(
      notesViewUrl,
      '_blank',
      `
          width=${windowWidth},
          height=${windowHeight},
          top=${adjustFromTop},
          location=no,
        `
    );
    openedNotesWindow.push(openWindow);
    this.setState({
      openedNotesWindow,
    });
  }

  showWhiteboardView = () => {
    let windowHeight = 440;
    let windowWidth = 630;

    let adjustFromTop = 0;

    const { openedWhiteboardWindow } = this.state;

    if (window.screen) {
      windowHeight = (window.screen.availHeight * 70) / 100;
      windowWidth = (window.screen.availWidth);
      adjustFromTop = (window.screen.availHeight * 15) / 100;
    }
    const { externalMeetingId } = this.props;
    //  * -----Uncomment the below lines after integration when getting purpose and client id and pass the url--- *
    let codeframeUrl = frontEndConfigFile.CODE_FRAME_URL.frontend;
    codeframeUrl = codeframeUrl.replace(/<<codeFrameConfigUrl>>/g, codeFrameURL);
    
    let whiteBoardUrl = frontEndConfigFile.WHITEBOARD.url;
    whiteBoardUrl = whiteBoardUrl.replace('<<codeFrameUrl>>', codeframeUrl);
    whiteBoardUrl = whiteBoardUrl.replace('<<purposeId>>', externalMeetingId);
    whiteBoardUrl = whiteBoardUrl.replace('<<clientId>>', '');

    const openWindow = window.open(
      whiteBoardUrl,
      '_blank',
      `
            width=${windowWidth},
            height=${windowHeight},
            top=${adjustFromTop},
            location=no,
          `
    );

    openedWhiteboardWindow.push(openWindow);

    this.setState({
      openedWhiteboardWindow,
    });
  }

  render () {
    const {
      showModeratorOptionsDialog,
      showParticipantsBoardDialogModal,
    } = this.state;

    const {
      meetingContentHistory,
      isLoadingContentHistory,
      participantsAccessingCodeShare,
      hasMeetingStarted,
      hasMeetingEnded,
      fetchParticipantsSharingBoard,
      meetingId,
      isFetchingParticipantsSharingBoard,
    } = this.props;

    let shouldShowSubmission = false;
    meetingContentHistory && meetingContentHistory.forEach(meeting => {
      if ( meeting.contentHistory && meeting.contentHistory.length > 0){
        shouldShowSubmission = true;
      }
    });
    
    return (
      <div>
        <CssBaseline />
        <AppBar
          className='interviewAppbarHeight'
          style={{
            background: theme.primary1Color,
            // height: '59px',
          }}
          position="fixed"
        >
          <Toolbar>
            <div className='company'>
              <div className='moderator-company-logo'>
                <a href='https://myanatomy.in/#/home'><img className='navbarLogoWidth' src={myAnatomyLogo} alt='MA'/></a>
              </div>
            </div>
            <div className='moderatorOptions'>
              <IconButton
                style={{
                  color: 'white',
                }}
                onClick={
                  showModeratorOptionsDialog ?
                    this.closeModeratorOptionsDialog
                    :
                    this.openModeratorOptionsDialog
                }
              >
                <SettingsApplicationsIcon />
              </IconButton>
            </div>
            {
              hasMeetingStarted && !hasMeetingEnded ?
                <div className='codeShareOptionForModerator'>
                  <IconButton
                    style={{
                      color: 'white',
                    }}
                    title = 'Open code share board'
                    onClick={() => {this.openParticipantsBoardDetailsDialog(meetingId);}}
                  >
                    <BiCodeBlock />
                  </IconButton>
                </div>
                :
                null
            }
            {
              hasMeetingStarted && !hasMeetingEnded ?
                <div className='notesOptionForModerator'>
                  <IconButton
                    style={{
                      color: 'white',
                    }}
                    title = 'Take Notes'
                    onClick={() => {this.showNotesView();}}
                  >
                    <AssignmentIcon />
                  </IconButton>
                </div>
                :
                null
            }
            {
              hasMeetingStarted && !hasMeetingEnded ?
                <div className='whiteboardOptionForModerator'>
                  <IconButton
                    style={{
                      color: 'white',
                    }}
                    title = 'Open Whiteboard'
                    onClick={() => {this.showWhiteboardView();}}
                  >
                    <CastForEducationIcon />
                  </IconButton>
                </div>
                :
                null
            }
          </Toolbar>
        </AppBar>
        <Dialog
          open = { showModeratorOptionsDialog }
          onClose = {this.closeModeratorOptionsDialog}
          fullWidth
        >
          <DialogTitle
            className = "moderatorOptionsDialogHeader"
            style = {{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            Moderator options
          </DialogTitle>
          <DialogContent
            style = {{
              display: 'flex',
              color: 'black',
              justifyContent: 'center',
              fontSize: '18px',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className='getMeetingSubmissionButtonsContainer'>
              <Button
                color='primary'
                className='getMeetingSubmissionsButton'
                onClick={this.getMeetingSubmissions}
              >
                {
                  meetingContentHistory && meetingContentHistory.length > 0 ?
                    <div>
                      Reload submissions
                    </div>
                    :
                    <div>
                      Get submissions
                    </div>
                }
              </Button>
              <div
                style={{
                  cursor: (meetingContentHistory && meetingContentHistory.length > 0) ? 'pointer' : 'not-allowed',
                }}
              >
                <IconButton
                  onClick={(meetingContentHistory && meetingContentHistory.length > 0) ?
                    this.downloadMeetingContentHistory : null}
                  disabled={!(meetingContentHistory && meetingContentHistory.length > 0)}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </div>
            </div>
            {
              shouldShowSubmission ?
                <div className='contentDetailsContainer'>
                  {
                    meetingContentHistory.map((contentDetails) => {
                      const clientDetails = contentDetails.clientDetails;
                      const clientId = clientDetails.id;
                      const name = clientDetails.name || defaultParticipantName;

                      const contentHistory = contentDetails.contentHistory;
                      return (
                        <div
                          key={clientId}
                          className = { contentHistory && contentHistory.length > 0 ? 'contentDetailsParticipantContainer' : '' }
                        >
                          <div className = { contentHistory && contentHistory.length > 0 ? 'contentDetailsParticipantName'
                            : 'hiddenParticipantname' }>
                          Participant : {name}
                          </div>
                          <div className='contentDetailsParticipantSubmissions'>
                            {
                              contentHistory.map((contentRecord, index) => {
                                const contentRecordContent = contentRecord.content;
                                return (
                                  <div
                                    key={index}
                                    className='contentDetailsSubmissionRecordContainer'
                                  >
                                    <div className='contentDetailsContent'>
                                      <div className='contentDetailsMetaDataContainer'>
                                        <div className='contentDetailsLanguageIndicator'>
                                          {contentRecordContent.language}
                                        </div>
                                        <div className='contentDetailsSumittedAt'>
                                        Submitted at:&nbsp;{moment(contentRecord.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                                        </div>
                                      </div>
                                      <div className='contentDetailsCode'>
                                        {contentRecordContent.code}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
                :
                null
            }
            {
              isLoadingContentHistory ?
                <Loader />
                :
                null
            }
          </DialogContent>
          <DialogActions>
            <button
              onClick = {this.closeModeratorOptionsDialog}
              color = "primary"
              className = "fetchParticipantsBoardDialogButton"
              id = 'closeModeratorOptionsDialog'
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      
        <Dialog
          open = { showParticipantsBoardDialogModal }
          onClose = {this.closeParticipantsBoardDetailsDialog}
          fullWidth
        >
          <DialogTitle
            className = 'participantsBoardDialogHeader'
          >
            Participant boards
          </DialogTitle>
          <DialogContent
            style = {{
              display: 'flex',
              color: 'black',
              justifyContent: 'center',
              fontSize: '18px',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {
              participantsAccessingCodeShare.length > 0 ?
                <div className = 'participantsDetailsTable'>
                  {participantsAccessingCodeShare.map((participant) => {
                    return (
                      <div
                        key = {participant.participantToken}
                        className = 'participantDetails'
                      >
                        <div className = 'participantName'>
                          <IconButton
                            style={{
                              color: theme.primary1Color,
                            }}
                          >
                            <PersonIcon className = 'nonClickablePointer'/>
                          </IconButton>&nbsp;{participant.participantName}
                        </div>
                        <div className = 'participantEmail'>
                          <IconButton
                            style={{
                              color: theme.primary1Color,
                            }}
                          >
                            <MailOutlineIcon className = 'nonClickablePointer'/>
                          </IconButton>&nbsp;{participant.participantEmail}
                        </div>
                        <div className = 'openParticipantBoard'>
                          <IconButton
                            style={{
                              color: theme.primary1Color,
                            }}
                            onClick = { () => {
                              this.showCodeShareBoard(participant.participantName, participant.participantToken);
                            }}
                            title = {`Click to open ${participant.participantName} board`}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                </div>
                :
                <div className = 'NoParticipantText'>
                No participant opened their board.
                </div>
            }
          </DialogContent>
          <DialogActions style = {{ paddingRight: '24px' }}>
            <button
              onClick = {() => fetchParticipantsSharingBoard(meetingId)}
              className = 'fetchParticipantsBoardDialogButton'
            >
              Refresh
            </button>
            <button
              onClick = {() => this.closeParticipantsBoardDetailsDialog()}
              className = 'closeParticipantsBoardDialog'
            >
              Cancel
            </button>
          </DialogActions>
          {
            isFetchingParticipantsSharingBoard ?
              <Loader />
              :
              null
          }
        </Dialog>
      </div>
    );
  }
}

ModeratorTopNavBar.propTypes = {
  getSubmissionsForMeeting: propTypes.func,
  meetingContentHistory: propTypes.array,
  isLoadingContentHistory: propTypes.bool,
  meetingId: propTypes.string,
  token: propTypes.string,
  participantsAccessingCodeShare: propTypes.array,
  hasMeetingStarted: propTypes.bool,
  hasMeetingEnded: propTypes.bool,
  fetchParticipantsSharingBoard: propTypes.func,
  isFetchingParticipantsSharingBoard: propTypes.bool,
  externalMeetingId: propTypes.string,
};

ModeratorTopNavBar.defaultProps = {
  isLoadingContentHistory: false,
};

export default ModeratorTopNavBar;
