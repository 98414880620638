import {
  GET_MEETING_DETAILS_USING_MEETING_TOKEN,
  GET_MEETING_DETAILS_USING_MEETING_TOKEN_PENDING,
  GET_MEETING_DETAILS_USING_MEETING_TOKEN_FULFILLED,
  GET_MEETING_DETAILS_USING_MEETING_TOKEN_REJECTED,
  START_MEETING,
  START_MEETING_PENDING,
  START_MEETING_FULFILLED,
  START_MEETING_REJECTED,
  VERIFY_PASSWORD_AND_JOIN_MEETING,
  VERIFY_PASSWORD_AND_JOIN_MEETING_PENDING,
  VERIFY_PASSWORD_AND_JOIN_MEETING_FULFILLED,
  VERIFY_PASSWORD_AND_JOIN_MEETING_REJECTED,
  GET_MEETING_DETAILS_FOR_PUBLIC_LINK,
  GET_MEETING_DETAILS_FOR_PUBLIC_LINK_PENDING,
  GET_MEETING_DETAILS_FOR_PUBLIC_LINK_FULFILLED,
  GET_MEETING_DETAILS_FOR_PUBLIC_LINK_REJECTED,
  JOIN_MEETING_WITH_PUBLIC_LINK,
  JOIN_MEETING_WITH_PUBLIC_LINK_PENDING,
  JOIN_MEETING_WITH_PUBLIC_LINK_FULFILLED,
  JOIN_MEETING_WITH_PUBLIC_LINK_REJECTED,
  FETCH_ALL_MEETINGS,
  FETCH_ALL_MEETINGS_PENDING,
  FETCH_ALL_MEETINGS_FULFILLED,
  FETCH_ALL_MEETINGS_REJECTED,
  GET_MEETING_DETAILS_WITH_MEETING_ID,
  GET_MEETING_DETAILS_WITH_MEETING_ID_PENDING,
  GET_MEETING_DETAILS_WITH_MEETING_ID_FULFILLED,
  GET_MEETING_DETAILS_WITH_MEETING_ID_REJECTED,
  CLEAR_ALL_MEETING_DATA,
  GET_MEETING_SUBMISSIONS,
  GET_MEETING_SUBMISSIONS_PENDING,
  GET_MEETING_SUBMISSIONS_FULFILLED,
  GET_MEETING_SUBMISSIONS_REJECTED,
  FETCH_PARTICIPANTS_SHARING_BOARD,
  FETCH_PARTICIPANTS_SHARING_BOARD_PENDING,
  FETCH_PARTICIPANTS_SHARING_BOARD_FULFILLED,
  FETCH_PARTICIPANTS_SHARING_BOARD_REJECTED,
} from './actionTypes';

import {
  showErrorToast,showSuccessToast,
} from '../../utils/toasts';
import { pageSizes } from '../../configFiles/config';

const defaultState = {
  hasMeetingStarted: false,
  hasMeetingEnded: false,
  meetingId: undefined,
  isLobbyControlEnabled: false,
  isModerator: false,
  name: undefined,
  email: undefined,
  isModeratorControlEnabled: false,
  isPasswordAccessEnabled: false,
  isFetchingMeetingDetails: false,
  isStartingMeeting: false,
  connectingVideoId: undefined,
  isVerifyingMeetingPassword: false,
  isPasswordVerified: false,
  isFetchingPublicJoinPageDetails: false,
  title: '',
  isJoiningMeetingUsingPublicLink: false,
  pageNumberForFetchingAllMeetings: 0,
  allMeetings: {},
  isLoadingPageForMeetings: false,
  isFetchingMeetingDetailsForAdmin: false,
  meetingDetailsOfSelectedMeeting: {},
  hasMorePagesOfMeetingDataToFetch: true,
  isFetchingMeetingContentHistory: false,
  meetingContentHistory: [],
  participantsAccessingCodeShare: [],
  isFetchingParticipantsSharingBoard: false,
  useMyMeetingMicroService: false,
};

const meeting = (state = defaultState, action) => {
  switch (action.type) {
  case GET_MEETING_DETAILS_USING_MEETING_TOKEN: {
    return {
      ...state,
      isFetchingMeetingDetails: true,
    };
  }

  case GET_MEETING_DETAILS_USING_MEETING_TOKEN_PENDING: {
    return {
      ...state,
      isFetchingMeetingDetails: true,
    };
  }

  case GET_MEETING_DETAILS_USING_MEETING_TOKEN_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isFetchingMeetingDetails: false,
      };
    }

    const {
      meetingDetails,
    } = data;


    if (!meetingDetails) {
      return {
        ...state,
        isFetchingMeetingDetails: false,
      };
    }

    const {
      meetingId,
      email,
      hasMeetingStarted,
      isLobbyControlEnabled,
      isModerator,
      isModeratorControlEnabled,
      isPasswordAccessEnabled,
      name,
      connectingVideoId,
      title,
      useMyMeetingMicroService,
    } = meetingDetails;

    return {
      ...state,
      isFetchingMeetingDetails: false,
      meetingId,
      email,
      hasMeetingStarted,
      isLobbyControlEnabled,
      isModerator,
      isModeratorControlEnabled,
      isPasswordAccessEnabled,
      name,
      connectingVideoId,
      title,
      useMyMeetingMicroService,
    };
  }

  case GET_MEETING_DETAILS_USING_MEETING_TOKEN_REJECTED: {
    showErrorToast('Could not fetch meeting details');
    return {
      ...state,
      isFetchingMeetingDetails: false,
    };
  }

  case START_MEETING: {
    return {
      ...state,
      isStartingMeeting: true,
    };
  }

  case START_MEETING_PENDING: {
    return {
      ...state,
      isStartingMeeting: true,
    };
  }

  case START_MEETING_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isStartingMeeting: false,
      };
    }

    const {
      connectingVideoId,
    } = data;

    if (!connectingVideoId) {
      return {
        ...state,
        isStartingMeeting: false,
      };
    }

    return {
      ...state,
      isStartingMeeting: false,
      hasMeetingStarted: true,
      connectingVideoId,
    };
  }

  case START_MEETING_REJECTED: {
    showErrorToast('Could not start meeting at this time');
    return {
      ...state,
      isStartingMeeting: false,
    };
  }

  case VERIFY_PASSWORD_AND_JOIN_MEETING: {
    return {
      ...state,
      isVerifyingMeetingPassword: true,
    };
  }

  case VERIFY_PASSWORD_AND_JOIN_MEETING_PENDING: {
    return {
      ...state,
      isVerifyingMeetingPassword: true,
    };
  }

  case VERIFY_PASSWORD_AND_JOIN_MEETING_FULFILLED: {
    const {
      data,
    } = action.payload;

    if (!data) {
      return {
        ...state,
        isVerifyingMeetingPassword: false,
      };
    }

    const {
      connectingVideoId,
    } = data;

    if (!connectingVideoId) {
      return {
        ...state,
        isVerifyingMeetingPassword: false,
      };
    }

    return {
      ...state,
      isVerifyingMeetingPassword: false,
      isPasswordVerified: true,
      connectingVideoId,
    };
  }

  case VERIFY_PASSWORD_AND_JOIN_MEETING_REJECTED: {
    return {
      ...state,
      isVerifyingMeetingPassword: false,
    };
  }

  case GET_MEETING_DETAILS_FOR_PUBLIC_LINK: {
    return {
      ...state,
      isFetchingPublicJoinPageDetails: true,
    };
  }

  case GET_MEETING_DETAILS_FOR_PUBLIC_LINK_PENDING: {
    return {
      ...state,
      isFetchingPublicJoinPageDetails: true,
    };
  }

  case GET_MEETING_DETAILS_FOR_PUBLIC_LINK_FULFILLED: {
    const {
      data,
    } = action.payload;

    if (!data) {
      return {
        ...state,
        isFetchingPublicJoinPageDetails: false,
      };
    }

    const {
      meetingDetails,
    } = data;

    if (!meetingDetails) {
      return {
        ...state,
        isFetchingPublicJoinPageDetails: false,
      };
    }

    const {
      meetingId,
      title,
    } = meetingDetails;

    return {
      ...state,
      isFetchingPublicJoinPageDetails: false,
      meetingId,
      title,
    };
  }

  case GET_MEETING_DETAILS_FOR_PUBLIC_LINK_REJECTED: {
    return {
      ...state,
      isFetchingPublicJoinPageDetails: false,
    };
  }

  case JOIN_MEETING_WITH_PUBLIC_LINK: {
    return {
      ...state,
      isJoiningMeetingUsingPublicLink: true,
    };
  }

  case JOIN_MEETING_WITH_PUBLIC_LINK_PENDING: {
    return {
      ...state,
      isJoiningMeetingUsingPublicLink: true,
    };
  }

  case JOIN_MEETING_WITH_PUBLIC_LINK_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isJoiningMeetingUsingPublicLink: false,
      };
    }

    const {
      meetingDetails,
    } = data;

    if (!meetingDetails) {
      return {
        ...state,
        isJoiningMeetingUsingPublicLink: false,
      };
    }

    
    const {
      title,
      connectingVideoId,
      email,
      hasMeetingStarted,
      isModerator,
      isPasswordAccessEnabled,
      name,
      meetingId,
      isLobbyControlEnabled,
      isModeratorControlEnabled,
      useMyMeetingMicroService,
    } = meetingDetails;
    return {
      ...state,
      isJoiningMeetingUsingPublicLink: false,
      meetingId,
      email,
      hasMeetingStarted,
      isLobbyControlEnabled,
      isModerator,
      isModeratorControlEnabled,
      isPasswordAccessEnabled,
      name,
      connectingVideoId,
      title,
      useMyMeetingMicroService,
    };
  }

  case JOIN_MEETING_WITH_PUBLIC_LINK_REJECTED: {
    return {
      ...state,
      isJoiningMeetingUsingPublicLink: false,
    };
  }

  case FETCH_ALL_MEETINGS: {
    return {
      ...state,
      isLoadingPageForMeetings: true,
    };
  }

  case FETCH_ALL_MEETINGS_PENDING: {
    return {
      ...state,
      isLoadingPageForMeetings: true,
    };
  }

  case FETCH_ALL_MEETINGS_FULFILLED: {
    const { data } = action.payload;

    if (!data) {
      return {
        ...state,
        isLoadingPageForMeetings: false,
      };
    }

    const { meetings } = data;

    if (!meetings) {
      return {
        ...state,
        isLoadingPageForMeetings: false,
      };
    }

    let hasMorePagesOfMeetingDataToFetch = true;
    if (meetings && (meetings.length < pageSizes.fetchAllMeeting)) {
      hasMorePagesOfMeetingDataToFetch = false;
    }

    const {
      pageNumberForFetchingAllMeetings,
      allMeetings,
    } = state;

    const copyOfMeetings = Object.assign({}, allMeetings);

    meetings.forEach((meeting) => {
      const meetingId = meeting._id;
      copyOfMeetings[meetingId] = meeting;
    });

    return {
      ...state,
      isLoadingPageForMeetings: false,
      allMeetings: copyOfMeetings,
      pageNumberForFetchingAllMeetings: pageNumberForFetchingAllMeetings + 1,
      hasMorePagesOfMeetingDataToFetch,
    };
  }

  case FETCH_ALL_MEETINGS_REJECTED: {
    return {
      ...state,
      isLoadingPageForMeetings: false,
    };
  }

  case GET_MEETING_DETAILS_WITH_MEETING_ID: {
    return {
      ...state,
      isFetchingMeetingDetailsForAdmin: true,
    };
  }

  case GET_MEETING_DETAILS_WITH_MEETING_ID_PENDING: {
    return {
      ...state,
      isFetchingMeetingDetailsForAdmin: true,
    };
  }

  case GET_MEETING_DETAILS_WITH_MEETING_ID_FULFILLED: {
    const { data } = action.payload;
    
    if (!data) {
      return {
        ...state,
        isFetchingMeetingDetailsForAdmin: false,
      };
    }

    const {
      meetingDetails,
    } = data;

    if (!meetingDetails) {
      return {
        ...state,
        isFetchingMeetingDetailsForAdmin: false,
      };
    }

    return {
      ...state,
      isFetchingMeetingDetailsForAdmin: false,
      meetingDetailsOfSelectedMeeting: meetingDetails,
    };
  }

  case GET_MEETING_DETAILS_WITH_MEETING_ID_REJECTED: {
    return {
      ...state,
      isFetchingMeetingDetailsForAdmin: false,
    };
  }

  case CLEAR_ALL_MEETING_DATA: {
    return {
      ...state,
      pageNumberForFetchingAllMeetings: 0,
      meetingDetailsOfSelectedMeeting: {},
      hasMorePagesOfMeetingDataToFetch: true,
      allMeetings: {},
    };
  }

  case GET_MEETING_SUBMISSIONS: {
    return {
      ...state,
      isFetchingMeetingContentHistory: true,
    };
  }

  case GET_MEETING_SUBMISSIONS_PENDING: {
    return {
      ...state,
      isFetchingMeetingContentHistory: true,
    };
  }

  case GET_MEETING_SUBMISSIONS_FULFILLED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isFetchingMeetingContentHistory: false,
      };
    }
    const {
      content,
    } = data;

    if (!content) {
      return {
        ...state,
        isFetchingMeetingContentHistory: false,
      };
    }

    return {
      ...state,
      isFetchingMeetingContentHistory: false,
      meetingContentHistory: content,
    };
  }

  case GET_MEETING_SUBMISSIONS_REJECTED: {
    showErrorToast('No submissions found');
    return {
      ...state,
      isFetchingMeetingContentHistory: false,
    };
  }

  case FETCH_PARTICIPANTS_SHARING_BOARD: {
    return {
      ...state,
      isFetchingParticipantsSharingBoard: true,
    };
  }

  case FETCH_PARTICIPANTS_SHARING_BOARD_PENDING: {
    return {
      ...state,
      isFetchingParticipantsSharingBoard: true,
    };
  }

  case FETCH_PARTICIPANTS_SHARING_BOARD_FULFILLED: {
    const { data } = action.payload;
    if (!data) {
      return {
        ...state,
        isFetchingParticipantsSharingBoard: false,
      };
    }
    const {
      participantsAccessingCodeShare,
    } = data;

    if (!participantsAccessingCodeShare) {
      return {
        ...state,
        isFetchingParticipantsSharingBoard: false,
      };
    }
    if (participantsAccessingCodeShare.length > 0) {
      showSuccessToast(`Participant's board details fetched succesfully`);
    } else {
      showSuccessToast('No participant opened their board');
    }
    return {
      ...state,
      isFetchingParticipantsSharingBoard: false,
      participantsAccessingCodeShare,
    };
  }

  case FETCH_PARTICIPANTS_SHARING_BOARD_REJECTED: {
    showErrorToast('Could not fetch participant details at this time');
    return {
      ...state,
      isFetchingParticipantsSharingBoard: false,
    };
  }


  default:
    return state;
  }
};

export default meeting;
