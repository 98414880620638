export const toolbarOptions = [
  'camera',
  'chat',
  'closedcaptions',
  'desktop',
  'download',
  'etherpad',
  'feedback',
  'filmstrip',
  'fullscreen',
  'hangup',
  'help',
  'highlight',
  'linktosalesforce',
  'livestreaming',
  'microphone',
  'noisesuppression',
  'participants-pane',
  'profile',
  'raisehand',
  'recording',
  'security',
  'select-background',
  'settings',
  'shareaudio',
  'sharedvideo',
  'shortcuts',
  'stats',
  'tileview',
  'toggle-camera',
  'videoquality',
  'whiteboard',
];